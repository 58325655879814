<template>
    <div class="obscure-wrapper">
        <div class="container-fluid ticket-section">
            <div class="row g-0">
                <div class="row">
                    <div class="col offset-md-3">
                        <h2>¡Transacción realizada con éxito!</h2>
                    </div>
                </div>
                <div class="col-md-7 offset-md-3">
                    <shopping-cart viewType="full" resume="ticket" title='Resumen de compra' />
                </div>
                <div class="row">
                    <div class="col-md-7 offset-md-3">
                        <div class="statement">
                            <div class="download-control">
                                <a :href="fileUrl" download="Comprobante de pago KnU.pdf" target="_blank"><Button>DESCARGAR COMPROBANTE</Button></a>
                            </div>

                            <div class="next-control">
                                <Button @click="toPrograms()" >IR A MI PERFIL</Button>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['idPayment'],
    data() {
        return {
            fileUrl: null
        }
    },
    computed: {
    },
    mounted() {
        this.downloadPDF()
    },
    methods: {

        async downloadPDF(){
            if (this.$loading) return;
            this.$loading = true;
            const response = await this.$kuAPI.getTicket(this.idPayment);
            var file = new Blob([response.data], { type: 'application/octet-stream' });
            this.fileUrl = URL.createObjectURL(file);
            this.$loading =  false;
        },
        toPrograms() {
            this.$router.push('/my-programs');
        }
    }
}
</script>
<style lang="scss" scoped>
.ticket-section {
    margin-top:100px;

    .statement {
        margin-top: 5%;   
        .download-control {
            text-align: center;
            display: block;
            margin-top: 5%;
        } 
        .next-control {
            margin-top: 10%;
            margin-bottom: 5%;
            float:right;
        }
    }
    
}
</style>
